<div
  *ngIf="PreLoader"
  class="bodyLoader"
  style="
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.7);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  "
>
  <div
    class="loader"
    style="
      position: fixed;
      top: 49%;
      left: 49%;
      transform: translate(-50%, -50%);
      z-index: 9999;
    "
  >
    <svg viewBox="0 0 80 80">
      <circle id="test" cx="40" cy="40" r="32"></circle>
    </svg>
  </div>

  <a
    class="dribbble"
    href="https://dribbble.com/shots/5878367-Loaders"
    target="_blank"
    ><img
      src="https://cdn.dribbble.com/assets/dribbble-ball-mark-2bd45f09c2fb58dbbfb44766d5d1d07c5a12972d602ef8b32204d28fa3dda554.svg"
      alt=""
  /></a>
</div>
<div class="container-fluid bg">
  <nav class="navbar navbar-expand-md navbar-light custom-navbar p-4" >
    <div class="d-flex flex-column flex-md-row align-items-center w-100">
        <div class="welcome p-1 order-md-1 " >
          <div class="p-2 " style="border-radius: 20px; background-color:  rgba(255, 255, 255, 0.5);; display: inline-block;">
            <h4 class=" welcome-text text-dark" >Welcome: <b><span class="capitalize">{{cname}}</span></b></h4>
          </div>
        </div>
        
        <a class="navbar-brand order-1 order-md-2 mx-auto " href="#">
            <img [src]="logoPath" width="230" alt="Logo">
        </a>
        
        <div class="navbar-nav p-2 order-3 order-md-3 logoutAtEndMobile">
          <!-- <div class="dropdown" (click)="toggleDropdown($event)">
            <button class="btn btn-light dropdown-toggle" style="text-transform: capitalize;" type="button">
            Menu
            </button> -->
            <!-- <div class="dropdown-menu" [class.show]="isDropdownOpen"> -->
              <!-- <a class="dropdown-itemLogOut" (click)="logout()" style="cursor: pointer;"><strong>Log Out</strong></a> -->
              <button class="btn btn-solid mt-2" (click)="logout()" type="submit">Log Out</button>

            <!-- </div> -->
          <!-- </div> -->
          
        </div>
    </div>
</nav>
  <section class="login-page section-b-space d-flex align-items-center mt-3">
    <div class="container">
      <div class="row justify-content-center">
        
        <div class="col-lg-6">
          <!-- <div class="text-center mb-3">
            <img
              alt="logo"
              class="img-fluid"
              [src]="logoPath"
              width="250"
              height="120"
            />
          </div> -->
          <div class="theme-card" style="border-radius: 20px; background-color:  rgba(255, 255, 255, 0.8);;">
            <form class="theme-form" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <div class="form-group">
                  <!-- <label for="country">Select Company:</label><br> -->

                  <select
                    class="form-control"
                    (change)="selectCountry($event.target.value)"
                  >
                    <option value="">Select Customer</option>
                    <option
                      *ngFor="let customer of customersList"
                      [value]="customer.id"
                    >
                      {{ customer.displayName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="text-center mt-3">
                <button class="btn btn-solid mt-2" type="submit">Next</button
                ><br /><br />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
